export default {
  formId: 'addPpc',
  formField: {
    productionDate: {
      name: 'production_date',
      label: 'Plan Date',
      placeholder: 'Enter Plan Date',
      requiredErrorText: 'Plan Date is required',
      analyticsName: 'ProductionDate'
    },
    grade: {
      name: 'grade',
      label: 'Grade',
      placeholder: 'Select Grade',
      requiredErrorText: 'Grade is mandatory',
      analyticsName: 'Grade'
    },
    partName: {
      name: 'part_name',
      label: 'Part Name',
      placeholder: 'Select Part Name',
      requiredErrorText: 'Part Name is mandatory',
      analyticsName: 'PartName'
    },
    partNumber: {
      name: 'part_no',
      label: 'Part No.',
      placeholder: 'Select Part No.',
      requiredErrorText: 'Part Number is mandatory',
      analyticsName: 'PartNumber'
    },
    quantity: {
      name: 'quantity',
      label: 'Qty',
      placeholder: 'Enter Qty',
      requiredErrorText: 'Quantity is mandatory',
      analyticsName: 'Quantity'
    },
    shift: {
      name: 'shift',
      label: 'Shift',
      placeholder: 'Select Shift',
      requiredErrorText: 'Shift is mandatory',
      analyticsName: 'Shift'
    },
    priority: {
      name: 'priority',
      label: 'Priority',
      placeholder: 'Select Priority',
      requiredErrorText: 'Priority is mandatory',
      analyticsName: 'Priority'
    },
    ppCastingItems: {
      name: 'production_plan_castings'
    }
  }
};
