import React, { memo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeepCompareEffect } from '../../hooks/useDeepCompare';
import useQuery from '../../hooks/useQuery';
import { getGradeList } from '../../redux/grade/gradeAction';
import { AnimatePresence, motion } from 'framer-motion';
import styles from './GradeListingTable.module.scss';
import { sendAnalytics } from '../../utils/analyticsUtils';
import ListingTableLoader from '../Loader/Common/Listing/ListingTableLoader';
import ListingTable from '../common/ListingTable/ListingTable';
import { getGradeTableSchema } from './GradeTableSchema';
import { isEafFurnace } from '../../utils/Grade';
import { Button } from 'aui-component-library';

export const getGradeTag = type => {
  const tagStyle = {
    Others: styles.othersTag,
    DI: styles.diTag,
    GCI: styles.gciTag,
    SS: styles.ssTag
  };
  const constructedClass = tagStyle?.[type] || styles.tagStyleDefault;
  return <div className={`${styles.tag} ${constructedClass}`}>{type}</div>;
};

const GradeListingTable = ({ getGradeList, gradeList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const [query, setQuery] = useQuery();
  const navigate = useNavigate();

  const tableSchema = getGradeTableSchema(query, selectedValue);
  useDeepCompareEffect(() => {
    const { duplicate, ...rest } = query;
    const apiParams = {
      ...rest,
      page_size: rest.page_size || 10
    };

    (async () => {
      try {
        setIsLoading(true);
        await getGradeList(apiParams);
      } catch (err) {
        throw new Error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [query]);

  const handleGradeRowClick = (e, row) => {
    const { furnace_type, id } = row;
    if (!query.duplicate) {
      const gradesNavigationUrl = !isEafFurnace(furnace_type)
        ? `/grades/${id}/edit`
        : `/grades/${id}`;
      navigate(gradesNavigationUrl);
    } else {
      setSelectedValue(id);
    }
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ marginTop: 20 }}>
          <ListingTableLoader />
        </div>
      ) : (
        <ListingTable
          onRowClick={handleGradeRowClick}
          tableSchema={tableSchema}
          tableData={gradeList?.results}
          tableCount={gradeList?.count}
          limit={query?.page_size || 10}
          changeRowStyle={() => styles.hoverEffectRow}
          headerClassNames={styles.gradeHeaderStyle}
          customTableContainerClass={
            query.duplicate ? styles.gradeTableContainer : null
          }
        />
      )}
      <AnimatePresence>
        {query.duplicate && (
          <motion.div
            className={styles.bottomContainer}
            initial={{ y: 300, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 300, opacity: 0 }}
          >
            <div className={styles.innerBottomContainer}>
              <div className={styles.stepOneButton}>
                <Button
                  variant='secondary'
                  onClick={() => setQuery(({ duplicate, ...rest }) => rest)}
                  icon='cancel'
                  text='CANCEL'
                />
                <Button
                  variant='primary'
                  disabled={!selectedValue}
                  onClick={() => {
                    const analytics_Details = {
                      defaultName: 'DuplicateGradeButton_inAddGrade_Modal',
                      defaultDetails:
                        'Number of times user clicks on the duplicate grade button after selecting a particular grade '
                    };
                    sendAnalytics(analytics_Details);
                    navigate(`/grades/add?duplicate=${selectedValue}`);
                  }}
                  text='DUPLICATE'
                  icon='duplicate'
                  type={'submit'}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

/**
 *function to access redux state
 *
 * @param {*} state state of redux
 * @returns {*} Data object from redux store
 */
function mapStateToProps(state) {
  return {
    gradeList: state.gradeList
  };
}
const mapDispatchToProps = {
  getGradeList
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(GradeListingTable));
