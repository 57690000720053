import { lazy } from 'react';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import { Spectrometer_route } from './SpectrometerRoute';
import { ChargeMix_route } from './ChargemixRoute';
import { Raw_materials_route } from './RawmaterialRoute';
import { Grades_route } from './GradeRoute';
import { Procurement_route } from './ProcurementRoute';
import { Melting_Section_route } from './MeltingRoute';
import { heat_plan_route } from './ProductionPlan';
import { CastingReportRoutes } from './CastingReportRoute';

const InternalAnalytics = lazy(() =>
  LoadLazyComponent(() =>
    import('../../pages/InternalAnalytics/pages/MainPage')
  )
);

export const admin_route = {
  name: 'Internal Analytics',
  child_routes: [
    {
      name: 'Internal Analytics',
      component: <InternalAnalytics />,
      path: '/internalAnalytics',
      permission: [],
      is_superuser: true
    }
  ],
  permission: []
};

export const RouteList = [
  heat_plan_route,
  Melting_Section_route,
  CastingReportRoutes,
  Spectrometer_route,
  ChargeMix_route,
  Raw_materials_route,
  Grades_route,
  Procurement_route
];
