import React from 'react';
import SvgImage from '../../utils/UI/Icons';

const createSvgComponent = imgSrc => props =>
  <SvgImage {...props} imgSrc={imgSrc} />;

export const PpcActiveIcon = createSvgComponent('PpcActiveIcon.svg');
export const TensileActiveIcon = createSvgComponent('TensileActiveIcon.svg');
export const MicroStructureActiveIcon = createSvgComponent(
  'MicroStructureActiveIcon.svg'
);
export const HardnessActiveIcon = createSvgComponent('Hardness.svg');
export const DashboardIcon = createSvgComponent('sidebar/Dashboard.svg');
export const HoldingIcon = createSvgComponent('sidebar/holding_icon.svg');
export const OrdersIcon = createSvgComponent('sidebar/Orders.svg');
export const ChargeMixIcon = createSvgComponent('sidebar/ChargeMix.svg');
export const NewChargeMixIcon = createSvgComponent('sidebar/NewChargeMix.svg');
export const SpectrometerIcon = createSvgComponent('sidebar/Spectrometer.svg');
export const ChatIcon = createSvgComponent('sidebar/Chat.svg');
export const BookADemoIcon = createSvgComponent('sidebar/BookADemo.svg');
export const BrochureIcon = createSvgComponent('sidebar/Brochure.svg');
export const RaiseRFQIcon = createSvgComponent('sidebar/RaiseRFQ.svg');
export const AboutSoftwareIcon = createSvgComponent(
  'sidebar/AboutSoftware.svg'
);
export const HeatsIcon = createSvgComponent('HeatsInActiveIcon.svg');
export const HeatsActiveIcon = createSvgComponent('HeatsActiveIcon.svg');
export const SupplierActiveIcon = createSvgComponent(
  'rawmaterial/SupplierActiveIcon.svg'
);
export const SupplierInactiveIcon = createSvgComponent(
  'rawmaterial/SupplierInactiveIcon.svg'
);
export const ControlPlanActiveIcon = createSvgComponent(
  'rawmaterial/ControlPlanActiveIcon.svg'
);
export const ControlPlanInactiveIcon = createSvgComponent(
  'rawmaterial/ControlPlanInactiveIcon.svg'
);
export const MenueIcon = createSvgComponent('sidebar/Menue.svg');
export const ActiveChargeMixIcon = createSvgComponent(
  'sidebar/ActiveChargeMixIcon.svg'
);
export const ActiveDashBoardIcon = createSvgComponent(
  'sidebar/ActiveDashBoard.svg'
);
export const ActiveHoldingIcon = createSvgComponent(
  'sidebar/ActiveHoldingIcon.svg'
);
export const ActiveOrdersIcon = createSvgComponent(
  'sidebar/ActiveOrdersIcon.svg'
);
export const ActivePowerInsightIcon = createSvgComponent(
  'sidebar/ActivePowerInsightIcon.svg'
);
export const ActiveRaiseRFQIcon = createSvgComponent(
  'sidebar/ActiveRaiseRFQIcon.svg'
);
export const ActiveSpectrometerIcon = createSvgComponent(
  'sidebar/ActiveSpectrometerIcon.svg'
);
export const PremiumIcon = createSvgComponent('sidebar/Premium.svg');
export const MenueCloseIcon = createSvgComponent('sidebar/MenueClose.svg');
export const PowerInsightIcon = createSvgComponent('sidebar/PowerInsight.svg');
export const UploadChargeMixIcon = createSvgComponent(
  'headers/UploadChargeMix.svg'
);
export const ProfileIcon = createSvgComponent('headers/Profile.svg');
export const SpectrometerBlueIcon = createSvgComponent(
  'headers/SpectrometerBlue.svg'
);
export const OrdersBlueIcon = createSvgComponent(`headers/OrdersBlue.svg`);
export const RightArrowIcon = createSvgComponent('headers/rightarrow.svg');
export const DownArrowIcon = createSvgComponent(`headers/downarrow.svg`);
export const NoInventoryIcon = createSvgComponent('headers/noinventory.svg');
export const PowerInsightsBlueIcon = createSvgComponent(
  'headers/PowerInsightsBlue.svg'
);
export const ConfidentIcon = createSvgComponent('features/Confident.svg');
export const ControlIcon = createSvgComponent('features/Control.svg');
export const CostSavingIcon = createSvgComponent('features/CostSaving.svg');
export const ROIIcon = createSvgComponent('features/ROI.svg');
export const PriceTagIcon = createSvgComponent('features/PriceTag.svg');
export const CirclePowerIcon = createSvgComponent(
  'powerInsight/GroupCirclePower.svg'
);
export const TrianglePowerIcon = createSvgComponent(
  'powerInsight/GroupTrianglePower.svg'
);
export const HomePowerIcon = createSvgComponent(
  'powerInsight/GroupHomePower.svg'
);
export const MeterIcon = createSvgComponent('powerInsight/VectorMeter.svg');
export const SavingsIcon = createSvgComponent('icons/savings.svg');
export const ViewMoreIcon = createSvgComponent('icons/ViewMore.svg');
export const ViewLessIcon = createSvgComponent('icons/ViewLess.svg');
export const RedInfoIcon = createSvgComponent('icons/RedInfo.svg');
export const NextIcon = createSvgComponent('icons/nextIcon.svg');
export const PenIcon = createSvgComponent('icons/penIcon.svg');
export const ErrorIcon = createSvgComponent('icons/MobileErrorIcon.svg');
export const DownloadTemplateIcon = createSvgComponent(
  'icons/downloadTemplate.svg'
);
export const InfoIcon = createSvgComponent('icons/infoIcon.svg');
export const BpCheckedIcon = createSvgComponent('icons/bpCheckedIcon.svg');
export const BpIcon = createSvgComponent('icons/bpIcon.svg');
export const MCLogoIcon = createSvgComponent('icons/mcIcon.svg');
export const rupeeIcon = createSvgComponent('icons/rupeeIcon.svg');
export const loginmoneyIcon = createSvgComponent('icons/loginmoneyIcon.svg');
export const ControlbarIcon = createSvgComponent('icons/barIcon.svg');
export const smileFaceIcon = createSvgComponent('icons/smileFace.svg');
export const CorrectNumberIcon = createSvgComponent(
  'icons/correctNumber.svg?w=16&h=16'
);
export const IncorrectNumberIcon = createSvgComponent(
  'icons/incorrectNumberIcon.svg'
);
export const CloneIcon = createSvgComponent('chargemix/clone.svg');
export const HistoryIcon = createSvgComponent('chargemix/history.svg');
export const GreenTickIcon = createSvgComponent('chargemix/greenTick.svg');
export const RedTickIcon = createSvgComponent('chargemix/redTick.svg');
export const RightArrowIconNew = createSvgComponent('chargemix/arrowRight.svg');
export const SearchIcon = createSvgComponent('chargemix/search.svg');
export const HistoryActiveIcon = createSvgComponent(
  'chargemix/historyActive.svg'
);
export const HistoryInActiveIcon = createSvgComponent(
  'chargemix/historyInActive.svg'
);
export const DeleteIconNew = createSvgComponent('chargemix/delete.svg');
export const PrintIcon = createSvgComponent('chargemix/printIcon.svg');
export const CalculateIcon = createSvgComponent('chargemix/calculate.svg');
export const ActiveRawMaterialIcon = createSvgComponent(
  'rawmaterial/RawMaterialActive.svg'
);
export const InactiveRawMaterialIcon = createSvgComponent(
  'rawmaterial/RawMaterialInactive.svg'
);
export const WarningIcon = createSvgComponent('rawmaterial/warning.svg');
export const CallIcon = createSvgComponent('rawmaterial/call.svg');
export const GreenTickSmall = createSvgComponent('rawmaterial/greenTick.svg');
export const DownArrowIconRaw = createSvgComponent('rawmaterial/downArrow.svg');
export const ActiveGradeIcon = createSvgComponent('grades/ActiveGradeIcon.svg');
export const InactiveGradeicon = createSvgComponent(
  'grades/InactiveGradeIcon.svg'
);
export const ActivePartsIcon = createSvgComponent(
  'sidebar/PartsActiveIcon.svg'
);
export const InActivePartsIcon = createSvgComponent(
  'sidebar/PartsInactiveIcon.svg'
);
export const PriceSavingsIcon = createSvgComponent(
  'dashboard/PriceSavings.svg'
);
export const ElectricitySavingsIcon = createSvgComponent(
  'dashboard/ElectricitySavings.svg'
);
export const BackArrowIcon = createSvgComponent('spectrometer/BackArrow.svg');
export const SpectrometerErrorIcon = createSvgComponent(
  'spectrometer/Error.svg'
);
export const NotInRangeDownArrowIcon = createSvgComponent(
  'spectrometer/NotInRangeDownArrow.svg'
);
export const AddProductPenIcon = createSvgComponent(
  'spectrometer/AddProductPen.svg'
);
export const QualityReportInactive = createSvgComponent(
  './inactive_test_icon.svg'
);
export const QualityReportActive = createSvgComponent('./active_test_icon.svg');
