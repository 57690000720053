import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import useDebouncedApiCall from '../../hooks/useDebounceApiCall';
import styles from './CustomAutocomplete.module.css';

const Autocomplete = ({
  userInput,
  onSelect,
  placeholderText,
  deSelectItem,
  callbackFunc,
  handleChange,
  apiQuery,
  getOptionLabel,
  getOptionValue,
  disabled
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(null);
  const [hideElement, toggleHideElement] = useState(0);
  const [suggestions, loading] = useDebouncedApiCall(
    callbackFunc,
    apiQuery,
    500
  );

  const handleInputChange = e => {
    const inputValue = e.target.value;
    handleChange(inputValue);
    setShowSuggestions(true);
    toggleHideElement(1);
    if (deSelectItem) {
      deSelectItem();
    }
  };

  const handleSuggestionClick = (suggestion, index) => {
    handleChange(getOptionValue ? getOptionValue(suggestion) : suggestion);
    onSelect(suggestion);
    setSelectedSuggestionIndex(index);
    toggleHideElement(0);
    setShowSuggestions(false);
    return null;
  };

  const handleInputBlur = () => {
    // toggleHideElement(0);
    setTimeout(() => {
      setShowSuggestions(false);
    }, 300);
  };

  return (
    <div className={styles.autocomplete}>
      <label style={{ width: '100%' }}>
        <input
          type='text'
          value={userInput}
          onFocus={() => {
            toggleHideElement(1);
            setShowSuggestions(true);
          }}
          disabled={disabled || false}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          placeholder={placeholderText}
        />
      </label>
      {showSuggestions && loading && (
        <div
          style={{ opacity: hideElement }}
          className={`${styles.loading} ${styles.suggestionsOverlay}`}
        >
          <CircularProgress />
        </div>
      )}
      {showSuggestions && suggestions.length > 0 ? (
        <div
          style={{ opacity: hideElement }}
          className={styles.suggestionsOverlay}
        >
          {(suggestions || []).map((suggestion, index) => {
            const isSelected = index === selectedSuggestionIndex;
            return (
              <div
                key={index}
                onClick={() => handleSuggestionClick(suggestion, index)}
                className={`${styles.suggestion} ${
                  isSelected && styles.suggestionActive
                }`}
                style={{
                  textOverflow: 'ellipsis',
                  overflowX: 'hidden',
                  whiteSpace: 'no-wrap',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <span>
                  {getOptionLabel ? getOptionLabel(suggestion) : suggestion}
                </span>
                {isSelected && (
                  <i
                    className='ri-check-line'
                    style={{ marginLeft: '8px' }}
                  ></i>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        showSuggestions &&
        !loading && (
          <div
            style={{ opacity: hideElement }}
            className={`${styles.noData} ${styles.suggestionsOverlay}`}
          >
            No data
          </div>
        )
      )}
    </div>
  );
};

export default Autocomplete;
