import { FormControl } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  getCmList_v2,
  getGradeList_v2,
  getISOTime,
  getSampleType
} from '../../../utils/Spectrometer';
import classes from './SpectroListingTable.module.scss';
import CustomAutocomplete from '../../General/CustomAutocomplete';
import { useSelector } from 'react-redux';
import { SPECTRO_CONSTS } from '../../../constants/Spectrometer';
import { handleDateChange } from '../../../utils';
import { Button } from 'aui-component-library';
import {
  getProductionPlanPartNumber,
  getProductionPlanPartnames
} from '../../../api/ProductionPlan';

const FilterDrawer = ({ handleClose, applyFilter, query, setQuery }) => {
  const filterInitialValue = {
    readings__sample_type: '' || query.readings__sample_type,
    heat_date__lte: '' || query.heat_date__lte,
    heat_date__gte: '' || query.heat_date__gte,
    grade: '' || query.grade,
    cm: '' || query.cm,
    grade__part_name: '' || query.grade__part_name,
    grade__part_no: '' || query.grade__part_no
  };

  const { START_DATE, END_DATE } = SPECTRO_CONSTS;

  const userDetail = useSelector(state => state.userDetail);

  const sampleTypeOptions = getSampleType(userDetail?.customer?.furnace_type);

  const [data, setData] = useState(filterInitialValue);
  const [text, setText] = useState('');
  const [chargeText, setChargeText] = useState('');
  const [partNameText, setPartNameText] = useState('');
  const [partNumberText, setPartNumberText] = useState('');

  useEffect(() => {
    setData(query);
  }, []);

  function applyFilter() {
    const api_params = {
      ...query,
      readings__sample_type:
        data.readings__sample_type !== 'NULL'
          ? data.readings__sample_type
          : undefined,
      heat_date__lte: data.heat_date__lte ?? undefined,
      heat_date__gte: data.heat_date__gte ?? undefined,
      grade: data.grade ?? undefined,
      cm: data.cm,
      grade__part_name: data.grade__part_name || undefined,
      grade__part_no: data.grade__part_no || undefined
    };
    const filtered_params = Object.fromEntries(
      Object.entries(api_params).filter(([key, value]) => value !== undefined)
    );
    setQuery(filtered_params);
    handleClose();
  }

  function resetFilter() {
    const blankData = {
      ...query,
      heat_date__lte: '',
      heat_date__gte: '',
      grade: '',
      cm: '',
      readings__sample_type: '',
      grade__part_name: '',
      grade__part_no: ''
    };
    setQuery(blankData);
    setData(blankData);
    setChargeText('');
    setText('');
    setPartNameText('');
    setPartNumberText('');
    handleClose();
  }

  const handleChange = (type, value) => {
    const date_range = handleDateChange(type, value, data);
    setData(prev => ({ ...prev, ...date_range }));
  };

  return (
    <div className={classes.filterDrawerContainer}>
      <div className={classes.filterHeaderContainer}>
        <Button
          variant='text'
          icon='cancel'
          onClick={handleClose}
          className={classes.closeButton}
        />
        <div className={classes.filterHeader}>Filters</div>
        <div className={classes.horizontalLine} />
      </div>
      <div className={classes.filterBody}>
        <div className={classes.filterSubHeader}>Date Range</div>
        <div className={classes.dateRangeInput}>
          <input
            type='date'
            className={classes.inputbox_date}
            max={getISOTime()}
            value={data.heat_date__gte}
            onChange={e => handleChange(START_DATE, e.target.value)}
          />
          <span className={classes.spaceHifen}>-</span>
          <input
            type='date'
            className={classes.inputbox_date}
            max={getISOTime()}
            value={data.heat_date__lte}
            onChange={e => handleChange(END_DATE, e.target.value)}
          />
        </div>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Sample Type</div>
        <div className={classes.selectWrapper}>
          <select
            placeholder='Sample Type'
            id='outlined-basic'
            value={data.readings__sample_type}
            className={classes.selectBox}
            onChange={e =>
              setData({ ...data, readings__sample_type: e.target.value })
            }
          >
            {sampleTypeOptions.map(({ label, value }) => (
              <option key={label} value={value || label}>
                {label}
              </option>
            ))}
          </select>
          <div className={classes.arrow}>
            <i class='ri-arrow-down-s-line'></i>
          </div>
        </div>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Charge Mix</div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, cm: value?.id })}
              handleChange={value => setChargeText(value)}
              userInput={chargeText}
              placeholderText='Select the chargemix'
              label='Chargemix Name'
              getOptionLabel={option => option.cm_name}
              getOptionValue={option => option.cm_name}
              callbackFunc={getCmList_v2}
              apiQuery={chargeText}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Grade </div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, grade: value?.id })}
              getOptionLabel={option =>
                option.grade_code
                  ? `${option.name}(${option.grade_code})`
                  : option.name
              }
              userInput={text}
              placeholderText='Select a Metal Grade'
              label='Metal grade'
              getOptionValue={option =>
                option.grade_code
                  ? `${option.name}(${option.grade_code})`
                  : option.name
              }
              callbackFunc={getGradeList_v2}
              handleChange={value => setText(value)}
              apiQuery={text}
              deSelectItem={value => setData({ ...data, grade: '' })}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Part Name</div>
        <CustomAutocomplete
          onSelect={value => setData({ ...data, grade__part_name: value })}
          userInput={partNameText}
          placeholderText='Select a Part Name'
          label='Part Name'
          callbackFunc={getProductionPlanPartnames}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.name}
          handleChange={value => setPartNameText(value)}
          apiQuery={{ search: partNameText }}
          deSelectItem={value => setData({ ...data, grade__part_name: '' })}
        />
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Part Number</div>
        <CustomAutocomplete
          onSelect={value => setData({ ...data, grade__part_no: value })}
          userInput={partNumberText}
          placeholderText='Select a Part Number'
          label='Part Number'
          callbackFunc={getProductionPlanPartNumber}
          getOptionLabel={option => option.number}
          getOptionValue={option => option.number}
          handleChange={value => setPartNumberText(value)}
          apiQuery={{ search: partNumberText }}
          deSelectItem={value => setData({ ...data, grade__part_no: '' })}
        />
      </div>
      <div className={classes.filterBtnContainer}>
        <Button
          variant='outlined'
          onClick={resetFilter}
          icon='cancel'
          text='RESET'
        />
        <Button onClick={applyFilter} icon='check' text='APPLY' />
      </div>
    </div>
  );
};

export default FilterDrawer;
