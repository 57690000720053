import initialState from '../initial-state';
import { PPC_ACTION_TYPES } from './ppcActionTypes';

/**
 * Reducer for managing the PPC (Pay-Per-Click) list state.
 * @param {Array} state - The current state of the PPC list, initialized from initialState.
 * @param {Object} action - The dispatched Redux action.
 * @param {string} action.type - The type of action being dispatched.
 * @param {Array} [action.ppcList] - The updated PPC list when GET_PPC_LIST_SUCCESS action is dispatched.
 * @returns {Array} The updated PPC list state.
 */
export function ppcList(state = initialState.ppcList, action) {
  switch (action.type) {
    case PPC_ACTION_TYPES.GET_PPC_LIST_SUCCESS:
      return action.ppcList;
    default:
      return state;
  }
}

export const addPpcCurrentStep = (
  state = initialState.addPpcCurrentStep,
  action
) => {
  switch (action.type) {
    case PPC_ACTION_TYPES.ADD_CURRENT_PPC_STEP:
      return action.payload;
    default:
      return state;
  }
};

export const ppcDetail = (state = initialState.ppcDetail, action) => {
  switch (action.type) {
    case PPC_ACTION_TYPES.GET_PPC_DETAIL:
      return action.payload;
    default:
      return state;
  }
};
