import React, { lazy } from 'react';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import {
  ActiveGradeIcon,
  InactiveGradeicon,
  PpcActiveIcon
} from '../../assets/icons/modifiedIcons';
import { Slug } from './permissionConstants';
import ProductionPlanHeader from '../../componentsV2/Layout/Header/ProductionPlanHeader';
import PpcHeader from '../../componentsV2/Layout/Header/PpcHeader';

const ProductionPlan = lazy(() =>
  LoadLazyComponent(() => import('../../pages/ProductionPlan/index'))
);

const Ppc = lazy(() =>
  LoadLazyComponent(() => import('../../pages/Ppc/index'))
);

export const heat_plan_route = {
  name: 'PRODUCTION PLAN',
  can_view: [Slug.HEAT_PLAN, Slug.PRODUCTION_PLAN],
  child_routes: [
    {
      name: 'Production Plan',
      icon: PpcActiveIcon,
      active_icon: PpcActiveIcon,
      component: <Ppc />,
      header: () => <PpcHeader />,
      permission: [],
      slug: Slug.PRODUCTION_PLAN,
      path: 'productionplan/plan/*',
      matchRoutes: '^/productionplan/plan(?:/.*)?$'
    },
    {
      name: 'Heat Plan',
      icon: InactiveGradeicon,
      active_icon: ActiveGradeIcon,
      component: <ProductionPlan />,
      header: () => <ProductionPlanHeader />,
      permission: [],
      slug: Slug.HEAT_PLAN,
      path: 'productionplan/*',
      matchRoutes: '^/productionplan(?!/plan)(?:/.*)?$'
    }
  ]
};
