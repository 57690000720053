import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { productionPlanRoutes } from '../../../constants/Routes/RouteConst/productionPlan';

/**
 * Header component for the PPC module.
 *
 * Dynamically renders content based on the route, showing breadcrumbs, header text,
 * and a cancel modal for editing or duplicating plans. Supports responsive styling
 * and handles navigation with confirmation prompts.
 *
 * @returns {JSX.Element} Header for the PPC section.
 */
const PpcHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { plan_no = '' } = useSelector(state => state?.ppcDetail);
  const addPpcCurrentStep = useSelector(state => state?.addPpcCurrentStep);

  const isPpcDetail = pathMatch(
    productionPlanRoutes.listing,
    '/\\d+',
    location.pathname
  );
  const isAddPpc = location.pathname.includes('/add');
  const isEdit = location.pathname.includes('/edit');

  if (!isPpcDetail && !isAddPpc) {
    return <span>Production Plan</span>;
  }

  let headerText = '';
  if (isPpcDetail) {
    headerText = `${plan_no || '-'}`;
  } else if (isAddPpc) {
    headerText = `Add new Step - ${addPpcCurrentStep}`;
  } else if (isEdit) {
    headerText = 'Edit Plan';
  }

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <Breadcrumb
            navigate={path => {
              navigate(path);
            }}
            text='Production Plan'
            path='/productionplan/plan'
          />
        </>
        <i className='ri-arrow-right-s-line' />
        {headerText && (
          <span
            className={classes.heatDetailsHeaderText}
            style={{ fontSize: isMobile ? '12px' : '14px' }}
          >
            {headerText}
          </span>
        )}
      </div>
    </div>
  );
};

export default PpcHeader;
