import AddPpcFormModel from '../../componentsV2/Ppc/FormModel/AddPpcFormModel';

const {
  formField: {
    productionDate,
    grade,
    partName,
    partNumber,
    quantity,
    shift,
    priority,
    ppCastingItems
  }
} = AddPpcFormModel;

export const priorityOptions = [
  {
    id: 1,
    name: '! Low',
    value: 'LOW',
    color: '#2BA24C'
  },
  {
    id: 2,
    name: '!! Medium',
    value: 'MEDIUM',
    color: '#D58C00'
  },
  {
    id: 3,
    name: '!!! High',
    value: 'HIGH',
    color: '#E43E2B'
  }
];

export const addPpcSteps = ['Enter Plan Details', 'Verify Details'];

export const addPpcStepCount = {
  STEP_1: 1,
  STEP_2: 2
};

export const castingPpcInitialData = {
  [grade.name]: null,
  [partName.name]: null,
  [partNumber.name]: null,
  [quantity.name]: '',
  [shift.name]: null,
  [priority.name]: ''
};

export const addPpcFormInitalData = {
  [productionDate.name]: new Date().toISOString().split('T')[0],
  [ppCastingItems.name]: [castingPpcInitialData]
};

export const UNASSIGNED = 'Unassigned';
