import React, { Fragment } from 'react';
import { RouteList } from '../../../constants/Routes';
import {
  hasPaidModuleAccess,
  isModuleGroupedHeaderVisible
} from '../../../utils';
import { useSelector } from 'react-redux';
import classes from './SidebarLayout.module.scss';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { PremiumIcon } from '../../../assets/icons/modifiedIcons';
import usePermission from '../../../hooks/usePermissions';
import WithTooltip from '../../WithTooltip';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

const SidebarNavigationList = ({
  setOpen,
  sidebarOpen,
  handleSidebarToggle
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const hasPermission = usePermission();
  const userDetail = useSelector(state => state.userDetail);

  const handleSidebarRoute = tab => {
    const analytics_Details = {
      defaultName: `${tab.name}`,
      defaultDetails: `path : ${location.pathname}`
    };
    sendAnalytics(analytics_Details);
    navigate(tab.path.replace('/*', ''));
    isMobile && handleSidebarToggle();
  };

  const isActiveTab = tab =>
    tab.matchRoutes && new RegExp(tab.matchRoutes).test(location.pathname);

  const generateSidebarNavigationList = tab => {
    let isPermitted = hasPermission(tab.permission, tab.slug);

    if (tab.is_superuser && tab.is_staff) {
      isPermitted =
        isPermitted && (userDetail.is_superuser || userDetail.is_staff);
    } else if (tab.is_superuser) {
      isPermitted = isPermitted && userDetail.is_superuser;
    } else if (tab.is_staff) {
      isPermitted = isPermitted && userDetail.is_staff;
    }
    const listItemJsx = (
      <WithTooltip
        onMouseMovements={true}
        tooltipText={!sidebarOpen ? tab.name : ''}
        placement={'right'}
      >
        <motion.div
          className={clsx(
            classes.navigationItem,
            isActiveTab(tab) && classes.navItemSelected
          )}
        >
          <div
            style={{ display: isActiveTab(tab) ? 'block' : 'none' }}
            className={classes.selectedMarkerContainer}
          >
            <div className={classes.navSelectedmarker}></div>
            <tab.active_icon className={classes.navigationItemIcon} />
          </div>
          {!isActiveTab(tab) && (
            <tab.icon
              className={clsx(
                classes.navigationItemIcon,
                classes.inActiveNavigationIcon
              )}
            />
          )}
          <span className={classes.navigationItemText}>{tab.name}</span>
        </motion.div>
      </WithTooltip>
    );
    return {
      listItemJsx,
      isPermitted
    };
  };

  return (
    <div className={classes.featuresList}>
      {RouteList.map(
        ({ name, can_view, child_routes, paid_feature = false }, i) => {
          return isModuleGroupedHeaderVisible(can_view, userDetail) ? (
            <Fragment key={i}>
              {sidebarOpen ? (
                <div className={classes.headingText}>
                  <hr className={classes.horizontalLine} />
                  <span>{name}</span>
                </div>
              ) : (
                <hr />
              )}

              {!hasPaidModuleAccess(userDetail, paid_feature) &&
                child_routes.map(tab => {
                  const { listItemJsx, isPermitted } =
                    generateSidebarNavigationList(tab);
                  return isPermitted ? (
                    <div key={tab.name} onClick={() => handleSidebarRoute(tab)}>
                      {listItemJsx}
                    </div>
                  ) : (
                    <></>
                  );
                })}
            </Fragment>
          ) : (
            <Fragment key={i}>
              {hasPaidModuleAccess(userDetail, paid_feature) && sidebarOpen && (
                <div
                  className={classes.cm_header_style}
                  onClick={() => setOpen(true)}
                  key={i}
                >
                  <div className={classes.headingText}>
                    <span>{name}</span>
                  </div>
                  <PremiumIcon />
                </div>
              )}
            </Fragment>
          );
        }
      )}
    </div>
  );
};

export default SidebarNavigationList;
